<template>
  <div class="share">
    <el-dialog
      title="分享海师"
      :visible.sync="dialogVisible"
      center
      :before-close="handleClose"
    >
      <div>
        <div class="qrcode" ref="qrCodeUrl">
          <img src="" alt="">
        </div>
        <p>微信扫码，转发给好友</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props:['share_url'],
  data() {
    return {
      dialogVisible: true,
    };
  },
  created(){
  },
  mounted() {
    this.$nextTick(()=>{
      this.creatQrCode();
    })
  },
  methods: {
    //蒙层关闭触发回调
    handleClose() {
      this.$emit("share");
    },
    //生成二维码
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.share_url, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.share {
  .qrcode {
    display: inline-block;
    margin-bottom: 40px;
    // >img {
    //   background-color: #fff; //设置白色背景色
    //   padding: 6px; // 利用padding的特性，挤出白边
    //   box-sizing: border-box;
    // }
  }
  /deep/ .el-dialog {
    width: 450px;
    height: 411px;
    background: #ffffff;
    border-radius: 8px;
    .el-dialog__header {
      border-bottom: 1px solid #ededed;
      padding: 25px 0px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    .el-dialog__body {
      height: 332px;
      >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >p{
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
